import {
  Component,
  ElementRef,
  inject,
  viewChild,
  computed,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe],
  selector: 'app-confirm-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          data-dismiss="modal"
          (click)="dismiss()"
        ></button>
      </div>
      <div class="modal-body overflow-auto" style="max-height: 60vh">
        <div #bodyWrapper>
          <ng-content></ng-content>
        </div>
        @if (!hasBody()) {
          <div class="modal-body">
            @if (useDangerMessage) {
              <p>
                <strong>{{ bodyText | translate }}</strong>
              </p>
              @if (extraBodyItems.length > 0) {
                <ul class="list-group list-group-flush mt-2">
                  @for (item of extraBodyItems; track item) {
                    <li class="list-group-item text-white border-0">
                      {{ item }}
                    </li>
                  }
                </ul>
              }
            } @else {
              <span>{{ bodyText | translate }}</span>
              @if (extraBodyItems.length > 0) {
                <ul class="list-group list-group-flush mt-2">
                  @for (item of extraBodyItems; track item) {
                    <li class="list-group-item text-white border-0">
                      {{ item }}
                    </li>
                  }
                </ul>
              }
            }
          </div>
        }
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="dismiss()"
        >
          {{ dismissButtonText | translate }}
        </button>
        <button
          [disabled]="!closeButtonEnabled"
          type="button"
          [class.btn-outline-danger]="useDangerButton"
          [class.btn-outline-warning]="useWarningButton"
          [class.btn-outline-success]="!useDangerButton && !useWarningButton"
          class="btn"
          (click)="accept()"
        >
          {{ closeButtonText | translate }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmDialogComponent {
  /** compose a list of items in the body */
  @Input() extraBodyItems: string[] = [];
  /** enable/disable confirmation button */
  @Input() closeButtonEnabled = true;
  /** mark confirmation button as dangerous */
  @Input() useDangerButton = false;
  /** use danger message */
  @Input() useDangerMessage = false;
  /** use warning message */
  @Input() useWarningButton = false;
  /** label of NO button */
  @Input() dismissButtonText = 'NO';
  /** label of the confirmation button (YES) */
  @Input() closeButtonText = 'YES';

  @Input() headerText = 'CONFIRM_DIALOG_HEADER';
  @Input() bodyText = 'CONFIRM_DIALOG_MESSAGE';

  modal = inject(NgbActiveModal);
  bodyWrapper = viewChild.required<ElementRef>('bodyWrapper');

  hasBody = computed(
    () => this.bodyWrapper().nativeElement.childNodes.length > 0,
  );

  accept() {
    this.modal.close(true);
  }
  dismiss() {
    this.modal.dismiss();
  }
}
